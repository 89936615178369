import { useFeedItemsQuery } from '@graphql'
import { useGeolocation } from '@hooks/useGeolocation'
import { useEffect, useMemo, useState } from 'react'
import {
  FeedItemsListContext,
  FeedItemsListProviderProps,
} from './FeedItemsList.context'

export const FeedItemsListProvider = ({
  children,
}: FeedItemsListProviderProps) => {
  const [localItems, setLocalItems] = useState<any[]>([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [feedItemsList, setFeedItemsList] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const oneHourAgo = useMemo(() => new Date(Date.now() - 60 * 60 * 1000), [])
  const { position } = useGeolocation()

  const geoPoint = useMemo(() => {
    if (position) {
      return {
        geoPoint: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        },
      }
    } else {
      return {}
    }
  }, [position])

  const {
    data,
    loading: queryLoading,
    error,
    fetchMore,
  } = useFeedItemsQuery({
    variables: {
      includeSeen: true,
      maxItems: 20,
      excludeSeenBefore: oneHourAgo.toISOString(),
      ...geoPoint,
    },
    pollInterval: 0,
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setLocalItems(data?.feedItems || [])
      setHasNextPage((data?.feedItems?.length ?? 0) > 0)
    },
  })

  useEffect(() => {
    if (!data || queryLoading || error) return

    setFeedItemsList(data?.feedItems)
    setLoading(queryLoading)
  }, [data, queryLoading, error])

  return (
    <FeedItemsListContext.Provider
      value={{
        loading,
        feedItemsList,
        fetchMoreFeedItems: fetchMore,
        localItems,
        setLocalItems,
        hasNextPage,
        setHasNextPage,
      }}
    >
      {children}
    </FeedItemsListContext.Provider>
  )
}
