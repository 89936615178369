import { createContext } from 'react'

export type FeedItemsListProviderProps = {
  children: React.ReactNode
}

type FeedItemsListProviderState = {
  loading: boolean
  feedItemsList: any[]
  fetchMoreFeedItems: (options: any) => any
  localItems: any[]
  setLocalItems: (localItems: any[]) => void
  hasNextPage: boolean
  setHasNextPage: (hasNextPage: boolean) => void
}

const initialState = {
  loading: false,
  feedItemsList: [],
  fetchMoreFeedItems: () => null,
  localItems: [],
  setLocalItems: () => null,
  hasNextPage: false,
  setHasNextPage: () => null,
}

export const FeedItemsListContext =
  createContext<FeedItemsListProviderState>(initialState)
