import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  RedemptionLimitScope,
  RedemptionLimitType,
  RedemptionLimitUnit,
} from '@graphql'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  Box,
  Card,
  CloseIcon,
  ComboboxItem,
  ComboboxLikeRenderOptionInput,
  Group,
  Image,
  NumberInput,
  Select,
  Stack,
  Switch,
  Text,
} from '@mantine/core'
import { DateTimePicker, DateValue } from '@mantine/dates'
import { findLimit } from '@util/redemptionsUtils'
import dayjs from 'dayjs'
import { EditRedemptionFormShape } from './EditRedemptionForm'

const REDEMPTION_INPUT_MARGIN_LEFT = 52

type FormProps = {
  linkedProfileIdValue?: string | undefined
  linkedProfileIdOnChange: (value: string | null) => void
  donationGoalValue?: number | string | undefined
  donationGoalOnChange: (value: number | string | undefined) => void
  values: EditRedemptionFormShape
  setValues: (
    value: React.SetStateAction<Partial<EditRedemptionFormShape>>
  ) => void
}

type DonationGoalInputProps = {
  isProfileNonProfit: boolean
  disabled: boolean
  nonProfitCommunities:
    | Array<{
        label: string
        value: string
        image: string
      }>
    | undefined
  handleDonationGoalOnBlur: () => void
}

type DonationGoalNumberInputProps = Pick<
  DonationGoalInputProps,
  'handleDonationGoalOnBlur' | 'disabled'
> &
  Pick<
    FormProps,
    'donationGoalValue' | 'donationGoalOnChange' | 'values' | 'setValues'
  >

interface ExtendedComboboxItem extends ComboboxItem {
  image: string
}

const renderLinkProfileOption = (
  option: ComboboxLikeRenderOptionInput<ExtendedComboboxItem>
) => {
  return (
    <Group>
      <Box h={32} w={32}>
        <Image
          src={option.option.image}
          alt="Redemption Image"
          width={32}
          height={32}
        />
      </Box>
      <Text>{option.option.label}</Text>
    </Group>
  )
}

export function DonationGoalInput({
  isProfileNonProfit,
  nonProfitCommunities,
  handleDonationGoalOnBlur,
  linkedProfileIdValue,
  linkedProfileIdOnChange,
  donationGoalValue,
  donationGoalOnChange,
  values,
  setValues,
  disabled,
}: DonationGoalInputProps & FormProps) {
  return (
    <Card withBorder>
      <Stack gap={8}>
        {!isProfileNonProfit && (
          <Select
            disabled={disabled}
            label="Who are you donating to?"
            required
            styles={{
              label: {
                fontSize: '0.8rem',
              },
            }}
            data={nonProfitCommunities}
            renderOption={(option) =>
              renderLinkProfileOption(
                option as ComboboxLikeRenderOptionInput<ExtendedComboboxItem>
              )
            }
            placeholder={'Select a Non-Profit account'}
            value={linkedProfileIdValue}
            onChange={(value) => {
              linkedProfileIdOnChange(value)
            }}
          />
        )}
        <DonationGoalNumberInput
          disabled={disabled}
          handleDonationGoalOnBlur={handleDonationGoalOnBlur}
          values={values}
          setValues={setValues}
          donationGoalValue={donationGoalValue}
          donationGoalOnChange={donationGoalOnChange}
        />
      </Stack>
    </Card>
  )
}

function DonationGoalNumberInput({
  handleDonationGoalOnBlur,
  values,
  setValues,
  donationGoalValue,
  donationGoalOnChange,
  disabled,
}: DonationGoalNumberInputProps) {
  return (
    <NumberInput
      disabled={disabled}
      placeholder="Enter Goal"
      label="Donation Goal"
      styles={{
        label: {
          fontSize: '0.8rem',
        },
      }}
      step={values.amountInCents?.amount as number}
      leftSection={<SwayCashIcon className="h-4 w-4" />}
      allowNegative={false}
      allowDecimal={false}
      rightSection={
        <CloseIcon
          onClick={() => {
            setValues({
              ...values,
              donationGoal: '',
              limits: values.limits.filter(
                (limit) =>
                  limit.limitType !== RedemptionLimitType.Count ||
                  limit.limitScope !== RedemptionLimitScope.Global
              ),
            })
          }}
          className="cursor-pointer"
        />
      }
      onChange={donationGoalOnChange}
      value={donationGoalValue}
      min={1}
      description="Must be a multiple of the redemption cost"
      onBlur={handleDonationGoalOnBlur}
    />
  )
}

type ExpirationDateLimitInputProps = Pick<FormProps, 'values' | 'setValues'> & {
  value: DateValue | undefined
  onChange: () => void
}
export function ExpirationDateLimitInput({
  values,
  setValues,
  value,
  onChange,
}: ExpirationDateLimitInputProps) {
  return (
    <>
      <Switch
        defaultChecked={values.validEnd !== undefined}
        label="Expiration date"
        onChange={(event) => {
          if (event.currentTarget.checked) {
            setValues({
              ...values,
              validEnd: dayjs(new Date().toISOString()).add(7, 'day').toDate(),
            })
          } else {
            setValues({
              ...values,
              validEnd: undefined,
            })
          }
        }}
      />

      <Box ml={REDEMPTION_INPUT_MARGIN_LEFT}>
        {values.validEnd !== undefined && (
          <Card withBorder>
            <Text size="sm">This redemption will expire on:</Text>
            <DateTimePicker
              leftSection={<FontAwesomeIcon icon={faCalendar} />}
              minDate={new Date()}
              valueFormat="DD ddd MMM YYYY hh:mm A"
              value={value}
              onChange={onChange}
            />
          </Card>
        )}
      </Box>
    </>
  )
}

type LimitQuantityInputProps = Pick<FormProps, 'values'> & {
  value: number | undefined
  initialValues: EditRedemptionFormShape | undefined
  showNonProfitSectionField: boolean
  addLimit: (
    type: RedemptionLimitType,
    scope: RedemptionLimitScope,
    opts?: {
      unit?: RedemptionLimitUnit
      interval?: number
      limit?: number
    }
  ) => void
  removeLimit: (type: RedemptionLimitType, scope: RedemptionLimitScope) => void
  onChange: () => void
}

export function TotalLimitQuantityInput({
  values,
  value,
  initialValues,
  showNonProfitSectionField,
  addLimit,
  removeLimit,
  onChange,
}: LimitQuantityInputProps) {
  return (
    <>
      <Switch
        label="Limited Quantity (total)"
        disabled={showNonProfitSectionField}
        description={
          showNonProfitSectionField
            ? 'Disabled for Non-Profit redemptions, please use Donation Goal instead'
            : 'Total number of times this Redemption can be claimed across all members'
        }
        defaultChecked={
          findLimit(
            values.limits,
            RedemptionLimitType.Count,
            RedemptionLimitScope.Global
          ) && !initialValues?.linkedProfileId
        }
        onChange={(event) =>
          event.currentTarget.checked
            ? addLimit(RedemptionLimitType.Count, RedemptionLimitScope.Global, {
                limit: 50,
              })
            : removeLimit(
                RedemptionLimitType.Count,
                RedemptionLimitScope.Global
              )
        }
      />
      <Box ml={REDEMPTION_INPUT_MARGIN_LEFT}>
        <LimitedQuantityInput
          values={values}
          value={value}
          limitScope={RedemptionLimitScope.Global}
          showNonProfitSectionField={showNonProfitSectionField}
          onChange={onChange}
        />
      </Box>
    </>
  )
}

export function MemberLimitQuantityInput({
  values,
  value,
  initialValues,
  showNonProfitSectionField,
  addLimit,
  removeLimit,
  onChange,
}: LimitQuantityInputProps) {
  return (
    <>
      <Switch
        label="Limited Quantity (per member)"
        disabled={showNonProfitSectionField}
        description={
          showNonProfitSectionField
            ? 'Disabled for Non-Profit redemptions, please use Donation Goal instead'
            : 'Total number of times this Redemption can be claimed by a member'
        }
        defaultChecked={
          findLimit(
            values.limits,
            RedemptionLimitType.Count,
            RedemptionLimitScope.User
          ) && !initialValues?.linkedProfileId
        }
        onChange={(event) =>
          event.currentTarget.checked
            ? addLimit(RedemptionLimitType.Count, RedemptionLimitScope.User, {
                limit: 50,
              })
            : removeLimit(RedemptionLimitType.Count, RedemptionLimitScope.User)
        }
      />
      <Box ml={REDEMPTION_INPUT_MARGIN_LEFT}>
        <LimitedQuantityInput
          limitScope={RedemptionLimitScope.User}
          values={values}
          value={value}
          showNonProfitSectionField={showNonProfitSectionField}
          onChange={onChange}
        />
      </Box>
    </>
  )
}

function LimitedQuantityInput({
  limitScope,
  values,
  value,
  showNonProfitSectionField,
  onChange,
}: {
  limitScope: RedemptionLimitScope
  values: EditRedemptionFormShape
  value: number | undefined
  showNonProfitSectionField: boolean
  onChange: () => void
}) {
  const limitIndex = values.limits.findIndex(
    (limit) =>
      limit &&
      limit.limitType &&
      limit.limitType === RedemptionLimitType.Count &&
      limit.limitScope === limitScope
  )

  if (limitIndex === -1 || showNonProfitSectionField) {
    return null
  }

  return (
    <Card withBorder>
      <NumberInput
        placeholder="Enter Limit"
        required
        step={1}
        allowNegative={false}
        allowDecimal={false}
        min={1}
        value={value}
        onChange={onChange}
      />
    </Card>
  )
}

type MemberRateLimitInputProps = {
  initialValues: EditRedemptionFormShape | undefined
  limitValue: number | undefined
  values: EditRedemptionFormShape
  unitValue: string | undefined
  addLimit: (
    type: RedemptionLimitType,
    scope: RedemptionLimitScope,
    opts?: {
      unit?: RedemptionLimitUnit
      interval?: number
      limit?: number
    }
  ) => void
  removeLimit: (type: RedemptionLimitType, scope: RedemptionLimitScope) => void
  limitOnChange: () => void
  unitOnChange: () => void
}

export function MemberRateLimitInput({
  initialValues,
  values,
  limitValue,
  unitValue,
  addLimit,
  removeLimit,
  limitOnChange,
  unitOnChange,
}: MemberRateLimitInputProps) {
  return (
    <>
      <Switch
        label="Rate limit (per member)"
        defaultChecked={
          !!findLimit(
            initialValues?.limits,
            RedemptionLimitType.RateLimit,
            RedemptionLimitScope.User
          )
        }
        description="Frequency that each member can claim this Redemption"
        onChange={(event) =>
          event.currentTarget.checked
            ? addLimit(
                RedemptionLimitType.RateLimit,
                RedemptionLimitScope.User,
                {
                  unit: RedemptionLimitUnit.Day,
                  interval: 1,
                  limit: 1,
                }
              )
            : removeLimit(
                RedemptionLimitType.RateLimit,
                RedemptionLimitScope.User
              )
        }
      />
      {!!findLimit(
        values.limits,
        RedemptionLimitType.RateLimit,
        RedemptionLimitScope.User
      ) && (
        <Box ml={REDEMPTION_INPUT_MARGIN_LEFT}>
          <Card withBorder>
            <Group>
              <NumberInput
                placeholder="Enter Limit"
                required
                step={1}
                allowNegative={false}
                allowDecimal={false}
                min={1}
                value={limitValue}
                onChange={limitOnChange}
              />
              <Select
                data={[
                  { label: 'per day', value: RedemptionLimitUnit.Day },
                  { label: 'per week', value: RedemptionLimitUnit.Week },
                  { label: 'per month', value: RedemptionLimitUnit.Month },
                ]}
                defaultValue={RedemptionLimitUnit.Day}
                placeholder="Select Time Unit"
                value={unitValue}
                onChange={unitOnChange}
              />
            </Group>
          </Card>
        </Box>
      )}
    </>
  )
}
