import { faHeart as faHeartLight } from '@fortawesome/free-regular-svg-icons'
import {
  faHeart as faHeartSolid,
  faShare,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { ActionIcon, Avatar, Box, Stack } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import useFeedStore from '@stores/useFeedStore'
import { useRef } from 'react'
import { Link } from 'react-router'
import SavePopover from './SavePopover'
import SharePopover from './SharePopover'

export function Overlay({
  avatarImageUrl,
  saved,
  vendorDisplayName,
  handleSave,
  handleUnsave,
  handleShare,
  redirectOnAvatarClick = true,
  isFirstRedemptionOnFeed = false,
  isSecondRedemptionOnFeed = false,
}: {
  avatarImageUrl?: string
  saved?: boolean
  vendorDisplayName?: string
  handleUnsave?: () => void
  handleSave?: () => void
  handleShare?: () => void
  redirectOnAvatarClick?: boolean
  isFirstRedemptionOnFeed?: boolean
  isSecondRedemptionOnFeed?: boolean
}) {
  const isMobileViewport = useIsMobileViewport()

  const containerRef = useRef<HTMLDivElement>(null)
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 0.8,
  })

  const {
    userSeenSaveRedemptionPopover,
    userSavedRedemption,
    setUserSeenSaveRedemptionPopover,
    userSeenShareRedemptionPopover,
    userSharedRedemption,
    setUserSeenShareRedemptionPopover,
  } = useFeedStore()

  const isOlderThanOneMonth = (date: Date | null) => {
    if (!date) return false

    const oneMonthAgo = new Date()
    oneMonthAgo.setDate(oneMonthAgo.getDate() - 30)

    return new Date(date) < oneMonthAgo
  }

  const savedOverOneMonth =
    isOlderThanOneMonth(userSeenSaveRedemptionPopover) ||
    isOlderThanOneMonth(userSavedRedemption)

  const sharedOverOneMonth =
    isOlderThanOneMonth(userSeenShareRedemptionPopover) ||
    isOlderThanOneMonth(userSharedRedemption)

  const showSavePopover =
    isFirstRedemptionOnFeed &&
    !saved &&
    ((!userSeenSaveRedemptionPopover && !userSavedRedemption) ||
      savedOverOneMonth)

  const showSharePopover =
    isSecondRedemptionOnFeed &&
    ((!userSeenShareRedemptionPopover && !userSharedRedemption) ||
      sharedOverOneMonth)

  return (
    <Box
      pos="absolute"
      h="100%"
      w="100%"
      style={{ zIndex: 10, pointerEvents: 'none' }}
      ref={ref}
    >
      <Stack
        pos="absolute"
        align="center"
        justify="center"
        bottom={12}
        right={16}
        style={{ pointerEvents: 'auto' }}
      >
        {handleSave && handleUnsave && (
          <SavePopover
            inViewport={entry?.isIntersecting}
            opened={showSavePopover}
            onClose={() => setUserSeenSaveRedemptionPopover()}
          >
            <ActionIcon
              size={isMobileViewport ? '30' : '50'}
              variant="transparent"
              color={saved ? 'red' : 'white'}
              onClick={saved ? handleUnsave : handleSave}
            >
              <FontAwesomeIcon
                className={showSavePopover && !saved ? 'pulse-icon' : ''}
                icon={saved ? faHeartSolid : faHeartLight}
                size={isMobileViewport ? 'lg' : 'xl'}
              />
            </ActionIcon>
          </SavePopover>
        )}
        {handleShare && (
          <SharePopover
            inViewport={entry?.isIntersecting}
            opened={showSharePopover}
            onClose={() => setUserSeenShareRedemptionPopover()}
          >
            <ActionIcon
              size={isMobileViewport ? '30' : '50'}
              variant="transparent"
              color="white"
              onClick={handleShare}
            >
              <FontAwesomeIcon
                className={showSharePopover ? 'pulse-icon' : ''}
                icon={faShare}
                size={isMobileViewport ? 'lg' : 'xl'}
              />
            </ActionIcon>
          </SharePopover>
        )}
        {avatarImageUrl &&
          vendorDisplayName &&
          (redirectOnAvatarClick ? (
            <Avatar
              component={Link}
              to={`/profile/${vendorDisplayName}`}
              src={avatarImageUrl}
            />
          ) : (
            <Avatar src={avatarImageUrl} />
          ))}
        {avatarImageUrl && !vendorDisplayName && (
          <Avatar src={avatarImageUrl} />
        )}
      </Stack>
    </Box>
  )
}
