import { faShare } from '@fortawesome/free-solid-svg-icons'
import { SwayPopover } from './SwayPopover'

export default function SharePopover({
  inViewport,
  opened,
  children,
  onClose,
}: {
  inViewport: boolean | undefined
  opened: boolean
  children: React.ReactNode
  onClose: () => void
}) {
  if (inViewport && opened) {
    return (
      <SwayPopover
        opened={opened}
        onClose={onClose}
        title={'Share your first redemption!'}
        text={'Share redemptions with friends!'}
        icon={faShare}
      >
        {children}
      </SwayPopover>
    )
  } else {
    return children
  }
}
