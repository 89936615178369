import {
  FilterFormValues,
  useRedemptionFiltersContext,
} from '@context/redemptionFiltersContext'
import { FILTERS } from '@context/redemptionFiltersContext/redemptionFilters.constants'
import { useGetLocation } from '@hooks/useGetLocation'

import {
  MediaOption,
  PageOrderDirection,
  Redemption,
  RedemptionQueryAttributes,
  RedemptionsDiscoveryQueryVariables,
  useRedemptionsDiscoveryQuery,
} from '@graphql'
import {
  useDebouncedValue,
  useInViewport,
  useIsFirstRender,
} from '@mantine/hooks'
import { generateImgixOptions } from '@util/imgixUtils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { RedemptionsDisplay } from './components/RedemptionsDisplay'

const imgixOpts: MediaOption[] = generateImgixOptions({
  w: 256,
  h: 256,
  fit: 'crop',
  auto: 'compress',
})

const DEFAULT_LIMIT = 100

export const RedemptionsPage = () => {
  const params = useParams<{ redemptionId?: string }>()

  const [searchValue, setSearchValue] = useState('')
  const { ref, inViewport } = useInViewport()
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 350)
  const { position } = useGetLocation()
  const isFirstRender = useIsFirstRender()

  const { filtersForm, setFilters, generateFlopEntry, buildQueryArguments } =
    useRedemptionFiltersContext()

  const userLngLatPoint = position
    ? {
        latitude: position.coords.latitude as number,
        longitude: position.coords.longitude as number,
      }
    : null

  const [initialUserLocation, setInitialUserLocation] =
    useState(userLngLatPoint)

  // Current filter query variables
  const filtersQueryArguments = buildQueryArguments(
    filtersForm.values,
    position,
    imgixOpts,
    DEFAULT_LIMIT
  )

  const [queryArguments, setQueryArguments] =
    useState<RedemptionsDiscoveryQueryVariables>({
      query: {
        filters: [
          ...generateFlopEntry(FILTERS.FULL_TEXT, debouncedSearchValue)
            .filtersInput,
          ...generateFlopEntry(FILTERS.RADIUS_KM, 100).filtersInput,
        ],
        orderBy: [
          {
            attribute: RedemptionQueryAttributes.IsSweepstakes,
            direction: PageOrderDirection.Desc,
          },
          {
            attribute: RedemptionQueryAttributes.RadiusKm,
            direction: PageOrderDirection.Asc,
          },
        ],
        limit: DEFAULT_LIMIT,
        offset: 0,
      },
      imgixOpts: imgixOpts,
      currentLocation:
        (filtersForm.values.useCurrentLocation && initialUserLocation) || null,
    })

  const {
    loading: loadingRedemptions,
    data: redemptionsData,
    refetch: refetchRedemptions,
    fetchMore: fetchMoreRedemptions,
    previousData,
  } = useRedemptionsDiscoveryQuery({
    variables: {
      query: {
        filters: [
          ...generateFlopEntry(FILTERS.FULL_TEXT, debouncedSearchValue)
            .filtersInput,
          ...generateFlopEntry(FILTERS.RADIUS_KM, 100).filtersInput,
        ],
        orderBy: [
          {
            attribute: RedemptionQueryAttributes.IsSweepstakes,
            direction: PageOrderDirection.Desc,
          },
          {
            attribute: RedemptionQueryAttributes.RadiusKm,
            direction: PageOrderDirection.Asc,
          },
        ],
        limit: DEFAULT_LIMIT,
        offset: 0,
      },
      imgixOpts: imgixOpts,
      currentLocation:
        (filtersForm.values.useCurrentLocation && initialUserLocation) || null,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  })

  const redemptions = useMemo(() => {
    if (redemptionsData?.discoverRedemptions?.data) {
      return redemptionsData.discoverRedemptions.data
    }

    // While the redemptions are being fetched (during a search), we continue displaying the previously items.
    if (loadingRedemptions) {
      return previousData?.discoverRedemptions?.data || []
    }

    return []
  }, [
    loadingRedemptions,
    previousData?.discoverRedemptions?.data,
    redemptionsData?.discoverRedemptions?.data,
  ])

  const thereAreMoreRedemptions =
    (redemptionsData?.discoverRedemptions?.page?.totalCount as number) >
    redemptions.length

  // In the first render, the redemptions are fetched using the existing filters from the context (if they exists).
  // This makes sure that the filters are preserved when switching between redemption tabs (offers/saved).
  useEffect(() => {
    if (isFirstRender && filtersQueryArguments) {
      refetchRedemptions({
        query: {
          filters: [
            ...generateFlopEntry(FILTERS.FULL_TEXT, debouncedSearchValue)
              .filtersInput,
            ...generateFlopEntry(FILTERS.RADIUS_KM, 100).filtersInput,
            ...(filtersQueryArguments.query?.filters || []),
          ],
          orderBy: [
            {
              attribute: RedemptionQueryAttributes.IsSweepstakes,
              direction: PageOrderDirection.Desc,
            },
            {
              attribute: RedemptionQueryAttributes.RadiusKm,
              direction: PageOrderDirection.Asc,
            },
            ...(filtersQueryArguments.query?.orderBy || []),
          ],
          limit: DEFAULT_LIMIT,
          offset: 0,
        },
      })
    }
  }, [
    isFirstRender,
    filtersQueryArguments,
    refetchRedemptions,
    generateFlopEntry,
    debouncedSearchValue,
  ])

  useEffect(
    function loadMoreRedemptions() {
      if (inViewport && thereAreMoreRedemptions) {
        const newQueryArguments = {
          query: {
            ...queryArguments.query,
            offset: redemptions.length,
          },
          currentLocation: queryArguments.currentLocation,
          imgixOpts: imgixOpts,
        }

        setQueryArguments(newQueryArguments)

        fetchMoreRedemptions({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev
            return {
              ...fetchMoreResult,
              discoverRedemptions: {
                ...fetchMoreResult.discoverRedemptions,
                data: [
                  ...(prev.discoverRedemptions?.data as Redemption[]),
                  ...(fetchMoreResult.discoverRedemptions
                    ?.data as Redemption[]),
                ],
              },
            }
          },
          variables: {
            ...newQueryArguments,
          },
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inViewport]
  )

  // Update the current location in the query arguments when the user location changes
  // so the next fetch will use the new location
  useEffect(
    function updateInitialPosition() {
      if (
        filtersForm.values.useCurrentLocation &&
        !queryArguments.currentLocation &&
        userLngLatPoint
      ) {
        const newQueryArguments = {
          ...queryArguments,
          currentLocation: userLngLatPoint,
        }

        // Update the initial user location when the user location changes for the first time so we refetch the redemptions
        if (!initialUserLocation) {
          setInitialUserLocation(userLngLatPoint)
        }

        setQueryArguments(newQueryArguments)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userLngLatPoint]
  )

  const isSearching = searchValue !== debouncedSearchValue

  const handleFiltersFormRefetch = useCallback(
    (formValues: FilterFormValues) => {
      setFilters(formValues)

      const queryArguments = buildQueryArguments(
        formValues,
        position,
        imgixOpts,
        DEFAULT_LIMIT
      )

      setQueryArguments(queryArguments)
      refetchRedemptions(queryArguments)
    },
    [buildQueryArguments, position, refetchRedemptions, setFilters]
  )

  const isLoadingMoreRedemptions =
    redemptions.length > 0 &&
    loadingRedemptions &&
    (redemptionsData?.discoverRedemptions?.page?.totalCount as number) >
      DEFAULT_LIMIT

  const hasPreviousData = !!previousData

  return (
    <RedemptionsDisplay
      redemptions={redemptions as Array<Redemption>}
      redemptionId={params.redemptionId}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      hasPreviousData={hasPreviousData}
      isSearching={isSearching}
      loadingRedemptions={loadingRedemptions}
      thereAreMoreRedemptions={thereAreMoreRedemptions}
      imgixOpts={imgixOpts}
      isLoadingMoreRedemptions={isLoadingMoreRedemptions}
      viewportRef={ref}
      refetchRedemptions={refetchRedemptions}
      handleFiltersFormRefetch={handleFiltersFormRefetch}
    />
  )
}
