import { PermissionState } from '@capacitor/core'
import { useAnalyticsContext } from '@context/analyticsContext'
import { useCallback } from 'react'

/**
 * Use SwayAnaltyicsAdditionalProperties to provide arbitrary properties to segment events.
 * Good for providing additional context to events.
 */
export interface SwayAnaltyicsAdditionalProperties {
  additional_properties?: object
}

export interface UserLogin {
  userId: string
}

export interface UserLogout {
  userId: string
}

export interface UserSwitch {
  fromUserId: string
  toUserId: string
}

export interface FeedItem {
  itemId: string
  type: ImpressionType
  vendorId?: string
  profileId?: string
}

export interface AnalyticsDuration {
  durationMs: number
}

export interface FeedItemAction extends FeedItem, AnalyticsDuration {
  action: 'skipped' | 'viewed'
}

export interface PushNotificationAction {
  userId: string
  route: string
  event: string
  action: string
}

export interface LocationPermission {
  locationPermission: PermissionState
  platform: 'ios' | 'android' | 'web'
}

export enum FeedItemType {
  INTERACTION = 'FeedItemInteraction',
  PERSISTENT_BROADCAST_SUGGESTION = 'FeedItemPersistentBroadcastSuggestion',
  REDEMPTION_SUGGESTION = 'FeedItemRedemptionSuggestion',
  VENDOR_SUGGESTION = 'FeedItemVendorSuggestion',
  UNCLAIMED_VENDOR_SUGGESTION = 'FeedItemUnclaimedVendorSuggestion',
  NOTIFICATION = 'FeedItemNotification',
  POLL = 'FeedItemPoll',
}

export enum ImpressionType {
  BROADCAST = 'broadcast',
  PERSISTENT_BROADCAST = 'persistent-broadcast',
  REDEMPTION_SUGGESTION = 'redemption-suggestion',
  VENDOR_SUGGESTION = 'vendor-suggestion',
  UNCLAIMED_SUGGESTION = 'unclaimed-suggestion',
  VENDOR = 'vendor',
  REDEMPTION = 'redemption',
  NOTIFICATION = 'notification',
  POLL = 'poll',
}

export const feedItemTypeToImpressionType = {
  [FeedItemType.INTERACTION]: ImpressionType.BROADCAST,
  [FeedItemType.PERSISTENT_BROADCAST_SUGGESTION]:
    ImpressionType.PERSISTENT_BROADCAST,
  [FeedItemType.REDEMPTION_SUGGESTION]: ImpressionType.REDEMPTION_SUGGESTION,
  [FeedItemType.VENDOR_SUGGESTION]: ImpressionType.VENDOR_SUGGESTION,
  [FeedItemType.UNCLAIMED_VENDOR_SUGGESTION]:
    ImpressionType.UNCLAIMED_SUGGESTION,
  [FeedItemType.NOTIFICATION]: ImpressionType.NOTIFICATION,
  [FeedItemType.POLL]: ImpressionType.POLL,
}

export interface Impression {
  /**
   * The type the impression was created for.
   */
  targetType: ImpressionType

  /**
   * The ID of the type the impression was created for.
   * This should be a consistent identifier, and must be a valid uuid.
   */
  targetId: string

  /**
   * The source of the impression.  Where in the app did this happen?
   */
  actionSource:
    | 'feed'
    | 'profile-page'
    | 'redemption-page'
    | 'vendor-profile-page'

  /**
   * What action triggered the creation of the impression?
   */
  action: 'skipped' | 'viewed'

  /**
   * What profile triggered the creation of the impression?
   * Most likely this is the logged in user's profile_id.
   * This is required in order to determine unique views, or aggregating interactions by member.
   */

  profileId?: string | null

  /**
   * What vendor triggered the creation of the impression?
   * This is required in order to aggregate counts of impressions by vendor.
   */
  vendorId?: string

  /**
   * If there is a time component to an impression, you can use this attribute.
   * For example, how long did the user view a feed item before skipping.
   */
  deltaMs?: number
}

export type TrackEventKeys =
  | 'about-sway-clicked'
  | 'account-confirm'
  | 'contact-support-clicked'
  | 'copy-profile-link'
  | 'customize-link-clicked'
  | `feed-redemption-suggestion-${'donate' | 'redeem' | 'enter'}-clicked`
  | `feed-redemption-suggestion-${'donate' | 'redeem' | 'enter'}-completed`
  | `feed-redemption-suggestion-${'donate' | 'redeem' | 'enter'}-confirmed`
  | `feed-redemption-suggestion-${'donate' | 'redeem' | 'enter'}-error`
  | 'feed-redemption-suggestion-view-clicked'
  | 'follow-vendor-clicked'
  | 'get-app-clicked'
  | 'get-app-dismissed'
  | 'go-to-profile'
  | 'join-sway-form-submitted-error'
  | 'join-sway-form-submitted-success'
  | 'join-sway-form-submitted'
  | 'join-sway-form-viewed'
  | 'join-to-redeem'
  | 'login-clicked'
  | 'poll-answered'
  | 'poll-view-answer-again'
  | 'profile-avatar-clicked'
  | 'profile-link-clicked'
  | 'profile-non-profit-clicked'
  | 'qr-code-clicked'
  | 'redeem-clicked'
  | 'redeem-confirmation-clicked'
  | 'redeem-error'
  | 'redeem-maybe-later-clicked'
  | 'redeem-success'
  | 'redemption-clicked'
  | 'redemptions-filters-cleared'
  | 'redemptions-filters-opened'
  | 'redemptions-filters-removed'
  | 'redemptions-filters-submitted'
  | 'redemption-history-clicked'
  | `${'redemption' | 'profile'}-share-item-aborted`
  | `${'redemption' | 'profile'}-share-item`
  | `${'redemption' | 'profile'}-share-link`
  | `${'redemption' | 'profile'}-share-item-opened`
  | 'referral-profile-activity-clicked'
  | 'referral-qr-code-activity-clicked'
  | 'referral-url-copied'
  | 'register-and-follow-clicked'
  | 'register-vendor-clicked'
  | 'register-vendor-error'
  | 'register-vendor-success'
  | 'go-to-leaderboard-clicked'
  | 'profile-share-button-clicked'

export interface SwayAnaltyics {
  /**
   * Capture a user login event.
   *
   * @param userLogin
   * @param additional_properties
   */
  userLogin(
    userLogin: UserLogin,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture a user logout event.
   *
   * @param userLogin
   * @param additional_properties
   */
  userLogout(
    userLogout: UserLogout,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture a user switch event.
   *
   * @param userSwitch
   * @param additional_properties
   */
  userSwitch(
    userSwitch: UserSwitch,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture an impression.
   *
   * Used to track user activities across all aspects of the app.
   * Impressions can be rolled up into aggregates for analytics, and also used to
   * help influence future content the user may, or may not see.
   *
   * @param impression
   * @param additional_properties
   */
  impression(
    impression: Impression,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture an action on a feed item.
   *
   * This is simply a wrapper for creating impressions.
   *
   * @param feedItemAction
   * @param additional_properties
   */
  feedItemAction(
    feedItemAction: FeedItemAction,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture a push notificaiton action.
   *
   * @param pushNotificationAction
   * @param additional_properties
   */
  pushNotificationAction(
    pushNotificationAction: PushNotificationAction,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  /**
   * Capture a location permission update.
   *
   * @param locationPermission
   * @param additional_properties
   */
  locationPermission(
    locationPermission: LocationPermission,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void

  page(additional_properties?: SwayAnaltyicsAdditionalProperties): void

  track(
    event: TrackEventKeys,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void
}

const useAnalytics = (): SwayAnaltyics => {
  const { analytics } = useAnalyticsContext()

  const userLogin = useCallback(
    (
      userLogin: UserLogin,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.identify(userLogin.userId, additional_properties)
      analytics?.track('user:login', {
        ...userLogin,
        ...additional_properties,
      })
    },
    [analytics]
  )

  const userLogout = useCallback(
    (
      userLogout: UserLogout,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.track('user:logout', {
        ...userLogout,
        ...additional_properties,
      })
    },
    [analytics]
  )

  const userSwitch = useCallback(
    (
      userSwitch: UserSwitch,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.track('user:switch', {
        from_user_id: userSwitch.fromUserId,
        to_user_id: userSwitch.toUserId,
        ...additional_properties,
      })
      analytics?.identify(userSwitch.toUserId, additional_properties)
    },
    [analytics]
  )

  const feedItemAction = useCallback(
    (
      feedItemAction: FeedItemAction,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        const impression: Impression = {
          targetType: feedItemAction.type,
          targetId: feedItemAction.itemId,
          actionSource: 'feed',
          action: feedItemAction.action,
          profileId: feedItemAction.profileId,
          vendorId: feedItemAction.vendorId,
          deltaMs: feedItemAction.durationMs,
        }
        analytics?.track('impression', {
          ...impression,
          ...additional_properties,
        })
      }
    },
    [analytics]
  )

  const locationPermission = useCallback(
    (
      locationPermission: LocationPermission,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.track('location:permission', {
        ...locationPermission,
        ...additional_properties,
      })
    },
    [analytics]
  )

  const pushNotificationAction = useCallback(
    (
      pushNotificationAction: PushNotificationAction,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        analytics.track('push:action', {
          ...pushNotificationAction,
          ...additional_properties,
        })
      }
    },
    [analytics]
  )

  const impression = useCallback(
    (
      impression: Impression,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.track('impression', {
        ...impression,
        ...additional_properties,
      })
    },
    [analytics]
  )

  const page = useCallback(
    (additional_properties?: SwayAnaltyicsAdditionalProperties) => {
      analytics?.page(additional_properties)
    },
    [analytics]
  )

  const track = useCallback(
    (
      event: TrackEventKeys,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      analytics?.track(event, { ...additional_properties })
    },
    [analytics]
  )

  return {
    userLogin,
    userLogout,
    userSwitch,
    feedItemAction,
    pushNotificationAction,
    locationPermission,
    impression,
    page,
    track,
  }
}

export default useAnalytics
