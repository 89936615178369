import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'
import { RequireMember } from '../../routes/guards/RequireMember'
import { FeedItemsListProvider } from '@context/feedItemsListContext/FeedItemsListContext.provider'

export const FeedRoutes = [
  {
    path: 'feed/',
    async lazy() {
      const { FeedPage } = await import('./Feed.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'FeedRoutes')
            }}
          >
            <RequireMember>
              <FeedItemsListProvider>
                <FeedPage />
              </FeedItemsListProvider>
            </RequireMember>
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
]
