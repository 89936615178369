import { DevCommand } from '@components/devCommand/DevCommand'
import { AccountMenu } from '@components/menus'
import { AppBannerContent } from '@components/mobileAppPrompts/GetAppBanner'
import { GetAppModal } from '@components/mobileAppPrompts/GetAppModal'
import { GetAppPopup } from '@components/mobileAppPrompts/GetAppPopup'
import { KnockFeed } from '@components/notifications/KnockFeed.tsx'
import useAppContext from '@context/appContext/useAppContext'
import { ProfileType, useUserQuery } from '@graphql'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { Logo } from '@icons/Logo'
import { AppShell, Box, Burger, Divider, Group, Stack } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useScreenDimensionsStore } from '@stores/useScreenDimensionsStore'
import React, { useCallback } from 'react'
import { Link } from 'react-router'
import { SwayFooter } from './Footer'
import { SwayNavbar } from './Navbar'
import useLayoutContext from './useLayoutContext'

const SHOW_DEV_COMMAND = import.meta.env.VITE_ROOT_SHOW_DEV_COMMAND
const BANNER_HEIGHT = 48

export function AuthenticatedLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const { deviceInfo } = useAppContext()
  const [opened, { toggle, close }] = useDisclosure()
  const useMobileLayout = useBreakpoint('max', 'md')
  const { safeAreaInsets, keyboardHeight } = useScreenDimensionsStore()
  const {
    isHeaderTransparent,
    displayHeader,
    displayMobileToolbar,
    isGetAppSheetOpen,
    closeGetAppSheet,
    isGetAppModalOpen,
    openGetAppModal,
    closeGetAppModal,
    shouldShowAppBanner,
    hideShowAppBanner,
  } = useLayoutContext()

  const { data } = useUserQuery()

  const user = data?.user

  const closeSidebar = useCallback(() => {
    close()
  }, [close])

  const bannerHeightInHeader =
    deviceInfo?.platform === 'web' && shouldShowAppBanner && displayHeader
      ? BANNER_HEIGHT
      : 0

  const headerOffset = !useMobileLayout ? true : opened || !isHeaderTransparent

  return (
    <>
      <AppShell
        header={{
          height: {
            base: 60 + safeAreaInsets.top + bannerHeightInHeader,
            md: 70 + safeAreaInsets.top + bannerHeightInHeader,
          },
          collapsed: !displayHeader,
          offset: headerOffset,
        }}
        navbar={{
          width: 225,
          breakpoint: 'md',
          collapsed: { mobile: !opened },
        }}
        footer={{
          height: 90 + (keyboardHeight === 0 ? safeAreaInsets.bottom : 0),
          collapsed: !useMobileLayout || !displayMobileToolbar,
        }}
        offsetScrollbars
      >
        <AppShell.Header
          pt={safeAreaInsets.top}
          className="flex flex-col justify-end transition-colors"
          bg={
            isHeaderTransparent && !opened
              ? 'linear-gradient(to top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6))'
              : 'white'
          }
          withBorder={isHeaderTransparent ? false : true}
        >
          <Stack gap={0}>
            {deviceInfo?.platform === 'web' && (
              <Box
                pos="absolute"
                bg="deep-blue.9"
                top={0}
                left={0}
                right={0}
                h={BANNER_HEIGHT}
                py={4}
                c="white"
                hidden={!shouldShowAppBanner}
              >
                <Stack justify="center" h="100%">
                  <AppBannerContent
                    openGetAppModal={openGetAppModal}
                    hideShowAppBanner={hideShowAppBanner}
                  />
                </Stack>
              </Box>
            )}
            <Group justify="space-between" px="md" py={8}>
              <Burger
                opened={opened}
                onClick={toggle}
                hiddenFrom="md"
                size="md"
                color={isHeaderTransparent && !opened ? 'gray' : 'black'}
              />
              <Box visibleFrom="md" component={Link} to="/">
                <Logo />
              </Box>
              <Group>
                <KnockFeed
                  iconColor={isHeaderTransparent && !opened ? 'gray' : 'black'}
                />
                {!isHeaderTransparent && <Divider orientation="vertical" />}
                <AccountMenu />
              </Group>
            </Group>
          </Stack>
        </AppShell.Header>
        <AppShell.Navbar p="md" className="overflow-y-auto">
          <SwayNavbar closeSidebar={closeSidebar} />
        </AppShell.Navbar>
        {/* // Each page (child) should be wrapped by a main */}
        {children}
        {/* // ! bottom={0} is required or the footer will "float" above the bottom of the screen. */}
        <AppShell.Footer
          pb={
            !useMobileLayout || !displayMobileToolbar
              ? 0
              : safeAreaInsets.bottom
          }
          bottom={0}
          h={'calc(var(--app-shell-footer-height))'}
        >
          <SwayFooter />
        </AppShell.Footer>
      </AppShell>

      {/* Get App Prompts - should only show on web devices. */}
      {deviceInfo?.platform === 'web' && (
        <div className="visible z-20 sm:invisible">
          <GetAppPopup
            open={isGetAppSheetOpen}
            onContinue={closeGetAppSheet}
            profileType={
              user?.profile?.profileType || ProfileType.CommunityFollower
            }
          />
        </div>
      )}

      {deviceInfo?.platform === 'web' && (
        <GetAppModal show={isGetAppModalOpen} closeModal={closeGetAppModal} />
      )}

      {SHOW_DEV_COMMAND !== 'true' && <DevCommand />}
    </>
  )
}
